import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { WebDevelopmentComponent } from './web-development/web-development.component';
import { AppDevelopmentComponent } from './app-development/app-development.component';
import { SoftwareDevelopmentComponent } from './software-development/software-development.component';
import { SoftwareInternshipComponent } from './software-internship/software-internship.component';
import { DigitalMarketingComponent } from './digital-marketing/digital-marketing.component';
import { LogoDesignComponent } from './logo-design/logo-design.component';
import { OnlineTrainingComponent } from './online-training/online-training.component';
import { BulkSmsComponent } from './bulk-sms/bulk-sms.component';
import { WebPortfolioComponent } from './web-portfolio/web-portfolio.component';
import { AppPortfolioComponent } from './app-portfolio/app-portfolio.component';
import { LogoPortfolioComponent } from './logo-portfolio/logo-portfolio.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { RoboticsComponent } from './robotics/robotics.component';
import { CareerComponent } from './career/career.component';
const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'aboutUs', component: AboutUsComponent},
  { path: 'web-development', component: WebDevelopmentComponent},
  { path: 'app-development', component: AppDevelopmentComponent},
  { path: 'software-development', component: SoftwareDevelopmentComponent},
  { path: 'software-internship', component: SoftwareInternshipComponent},
  { path: 'digital-marketing', component: DigitalMarketingComponent},
  { path: 'logo-design', component: LogoDesignComponent},
  { path: 'online-training', component: OnlineTrainingComponent},
  { path: 'bulk-sms', component: BulkSmsComponent},
  { path: 'web-portfolio', component: WebPortfolioComponent},
  { path: 'app-portfolio', component: AppPortfolioComponent},
  { path: 'logo-portfolio', component: LogoPortfolioComponent},
  { path: 'contact-us', component: ContactUsComponent},
  { path: 'robotics', component: RoboticsComponent},
  { path: 'career', component: CareerComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
