<div class="content-wrapper banner-website-development">
	<div class="container">
		<div class="row">
			<div class="col-md-6 banner-tag">
				<h1 class="img-title head-title">Software Development</h1>
				<h4 class="img-subtitle sub-title">We Design and Develope Enterprise software application  as per your business requirement .</h4>
				<br>
			</div>
			<div class="col-md-6 hidden-xs hidden-sm">
				<img src="../../assets/images/soft1.png" class="img-responsive" alt="slider-1">
			</div>
		</div>
	</div>
</div>
<section class="web-section">
    <div class="container">
        <div class="row mb-70">
            <div class="col-md-6 col-sm-6">
            <div class="web-info">
              <h2 class="main-title content-title">Software Development</h2>
                <p class="common-description text-content">Get a high quality development services geared to the growth of your business. We Have a qualified and reliable software development team turns your project idea into reality.Our software development process (also known as software development methodology, model or life cycle) helps to structure, plan and control the process of developing business process into information systems.We strongly refers Software Development Life Cycle(SDLC) which includes processes such as initial search, data flow design, process flow design, flow diagrams, technical documentation, Software testing Debugging and other software architecture techniques</p>           
                </div>
            </div>
            <div class="col-md-6 col-sm-6">             
                  <img src="../../assets/images/soft2.png" class="img-responsive" alt="Web Design">              
            </div>
        </div>
    </div> 
</section>
<section  class="specialize-section">
    <div class="container">
        <div class="row"> 	
            <div class="col-md-6 col-sm-6">
                <div class="about-img">
                    <img src="../../assets/images/soft3.png" class="img-responsive" alt="About dsBit">      
                </div>
            </div>
            <div class="col-md-6 col-sm-6">
                <div class="specialize-info">
                    <h2 class="block-title">Below are the different stages of our software development which helps to structure, plan and control the process of developing business process into information systems:</h2>
                    <ul class="web-design-list text-content">
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Analyzing the problem</li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Market research</li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Requirements Gathering
                        </li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Devising a plan</li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Implementation</li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Testing & Deployment</li>
    
                     </ul>
                    
                </div>
            </div>
        </div>
    </div>
</section>
<div class="blue-design-summary">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <h3 class="inverse-text sub-title">We have dedicated expert team to deliver software development services. They work beyond compare both in matter of service, and quality. We provide solutions at competitive prices with any other.
                </h3>
            </div>
        </div>
    </div>
</div>