<div class="content-wrapper banner-website-development">
	<div class="container">
		<div class="row">
			<div class="col-md-6 banner-tag">
				<h1 class="img-title head-title">Internship</h1>
				<h4 class="img-subtitle sub-title">We Help students For Educate, Engage, And Inspire.</h4>
				<br>
			</div>
			<div class="col-md-6 hidden-xs hidden-sm">
				<img src="../../assets/images/Tecnolgy.png" class="img-responsive" alt="slider-1">
			</div>
		</div>
	</div>
</div>
<section class="web-section">
    <div class="container">
        <div class="row mb-70">
            <div class="col-md-6 col-sm-6">
            <div class="web-info">
              <h2 class="main-title content-title">InternShip</h2>
                <p class="common-description text-content">
					At dsBit Solutions, We have the special infrastructure for internship students with mentors allocated.We are Offering Internship On Live Project ,Web Technology,Machine Learning Ai Technology,Data Science
					we are Having Experience Staff Who Can Provide Deep Practical Skills.
					candidates Can Join Us for Long Time and Short Term Internship.
					<br>
					call us : 8669361164
				               </div>
            </div>
            <div class="col-md-6 col-sm-6">             
                  <img src="../../assets/images/in1.png" class="img-responsive" alt="Web Design">              
            </div>
        </div>
    </div> 
</section>

<div id="services">
	<div class="container">
		<div class="row">
			<div class="col-md-12">
				<h2 class="sub-title">Get started your project now...</h2>
				<br>
				<div class="row">
					<div class="col-sm-3 col-xs-6">
						<a href="javascript:;" class="servicesAnchor" data-scroll="mean-apps">
							<div class="icon_box icon_position_top">
								<div class="icon_wrapper">
									<i class="homesprite sprite-web-development"></i>
								</div>
							</div>
							<h4 class="title feature-title">Web Development</h4>
							<!-- <div class="desc hidden-xs text-content">Each Line of Code is Refined</div> -->
						</a>
					</div>
					<div class="col-sm-3 col-xs-6">
						<a href="javascript:;" class="servicesAnchor" data-scroll="mobileapp">
							<div class="icon_box icon_position_top">
								<div class="icon_wrapper">
									<i class="homesprite sprite-mobile-app"></i>
								</div>
							</div>
							<h4 class="title feature-title">Mobile App Development</h4>
							<!-- <div class="desc hidden-xs text-content">Unique and Feature-Rich Apps</div> -->
						</a>
					</div>
					
					<div class="col-sm-3 col-xs-6">
						<a href="javascript:;" class="servicesAnchor" data-scroll="aws-cloud">
							<div class="icon_box icon_position_top">
								<div class="icon_wrapper">
									<i class="homesprite sprite-aws-cloud"></i>
								</div>
							</div>
							<h4 class="title feature-title">AWS Cloud Services</h4>
							<!-- <div class="desc hidden-xs text-content">Creating Intuitive Interfaces</div> -->
						</a>
					</div>
					<div class="col-sm-3 col-xs-6">
						<a href="javascript:;" class="servicesAnchor" data-scroll="features">
							<div class="icon_box icon_position_top">
								<div class="icon_wrapper">
									<i class="homesprite sprite-ecommerce"></i>
								</div>
							</div>
							<h4 class="title feature-title">Ecommerce Development</h4>
							<!-- <div class="desc hidden-xs text-content">Better Return on Investment</div> -->
						</a>
					</div>
					<div class="clearfix visible-xs"></div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- <div class="website-design-summary">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <h3 class="inverse-text">As a web development company, We help brands educate, engage, and inspire their customers through gamut of online services and powerful designs</h3>
                <h3 class="inverse-text"></h3>
            </div>
        </div>
    </div>
</div> -->