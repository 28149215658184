<div id="banner-contactus" class="content-wrapper">
	<div class="container contact-tag-wrapper">
		<div class="row">
			<div class="col-sm-12 col-md-12">
				<h1 style="color:#fff;" class="head-title">Portfolio</h1>
			</div>
		</div>
	</div>
</div>
<div id="our-work">
	<div class="container">
		<div id="portfolio_row">
			<div class="row">
				<div class="col-sm-6 col-md-4">
					<div class="work-blog">
						<div class="portfolio-img-content">
							<img src="../../assets/images/port1.png"
							class="img-responsive work-pic">
						</div>
						
						<a>
							<div class="work-technologies" style="background:#dc4e44;">
								<img src="../../assets/images/angular.png"
									class="img-responsive">
								<img src="../../assets/images/css3.png"
									class="img-responsive">
								<img src="../../assets/images/ionic.png"
									class="img-responsive">
							</div>

							<div class="project-description">
								<div class="outer-wrapper">
									<div class="icon red">
										<img src="../../assets/images/mobile.png"
											class="img-responsive">
									</div>
								</div>
								<div class="description text-content" >
									<h5 class="feature-title">Sharadha VadhuVar Application</h5>
									<p>Shradha VadhuVar App, Data Fills on Mobile. It is development on Cross Platform with Angular &amp;
										Ionic framework. </p>
								</div>
							</div>
						</a>
					</div>
				</div>

				<div class="col-sm-6 col-md-4">
					<div class="work-blog">
						<div class="portfolio-img-content">
							<img src="../../assets/images/shreesidhi.jpg"
							class="img-responsive work-pic">
						</div>
						<a>
							<div class="work-technologies" style="background:#467dd9;">
								<img src="../../assets/images/bootstrap.png"
									class="img-responsive">
								<img src="../../assets/images/angular.png"
									class="img-responsive">
							</div>

							<div class="project-description">
								<div class="outer-wrapper">
									<div class="icon blue">
										<img src="../../assets/images/website.png"
											class="img-responsive">
									</div>
								</div>
								<div class="description text-content">
									<h5 class="feature-title">ShreeSidhi EPC pvt Ltd</h5>
									<p>We have created this website for Shree Shidhi Group Mumbai, a renowned project management consultancy.
										It is development on Cross Platform with Angular & Ionic framework. </p>
								</div>
							</div>
						</a>
					</div>
				</div>
				<div class="col-sm-6 col-md-4">
					<div class="work-blog">
						<div class="portfolio-img-content">
							<img src="../../assets/images/port2.png"
							class="img-responsive work-pic">
						</div>
						<a>
							<div class="work-technologies" style="background:#FFC107;">
								<img src="../../assets/images/cordova.png"
									class="img-responsive">
								<img src="../../assets/images/angular.png"
									class="img-responsive">
								<img src="../../assets/images/ionic.png"
									class="img-responsive">
							</div>

							<div class="project-description">
								<div class="outer-wrapper">
									<div class="icon yellow">
										<img src="../../assets/images/mobile.png"
											class="img-responsive">
									</div>
								</div>
								<div class="description">
									<h5 class="feature-title">School</h5>
									<p class="text-content">Online Assessment App, Bringing Exams on Mobile. This app is published by ionic
										in their showcase. It is development on Cross Platform with Angular JS &amp;
										Ionic framework. </p>
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-6 col-md-4">
					<div class="work-blog">
						<div class="portfolio-img-content">
							<img src="../../assets/images/sai.jpeg"
							class="img-responsive work-pic">
						</div>
					
						<a>
							<div class="work-technologies" style="background:#FFC107;">

								<img src="../../assets/images/bootstrap.png"
									class="img-responsive">
								<!-- <img src="https://www.angularminds.com/angular-minds-admin/uploads/project_tech_images/0d8482e82bc6f0cca5b572dbdc29e549.png"
									class="img-responsive"> -->
								<img src="../../assets/images/css3.png"
									class="img-responsive">
								<img src="../../assets/images/js.png"
									class="img-responsive">
							</div>

							<div class="project-description">
								<div class="outer-wrapper">
									<div class="icon 																																							yellow">
										<img src="../../assets/images/mobile.png"
											class="img-responsive">
									</div>
								</div>
								<div class="description">
									<h5 class="feature-title">Sai Auto Motors</h5>

									<p class="text-content">This app is designed for automobile service center, center owner can save all vehicle service details.
										It is development on Cross Platform with Angular & Ionic framework.
									</p>
								</div>
							</div>
						</a>
					</div>
				</div>

				<div class="col-sm-6 col-md-4">
					<div class="work-blog">
						<div class="portfolio-img-content">
							<img src="../../assets/images/bhavani.jpg"
							class="img-responsive work-pic">
						</div>
						<a>
							 <div
								class="work-technologies" style="background:#dc4e44;">
								<img src="../../assets/images/bootstrap.png"
									class="img-responsive">
								<img src="../../assets/images/js.png"
									class="img-responsive">
								<img src="../../assets/images/angular.png"
									class="img-responsive">
							</div>

							<div class="project-description">
								<div class="outer-wrapper">
									<div class="icon red																																							">
										<img src="../../assets/images/website.png"
											class="img-responsive">
									</div>
								</div>
								<div class="description">
									<h5 class="feature-title">Bhavani Agro</h5>
									<p class="text-content">This is eCommerce website for Online Mapro Products Shopping. Beautifully
										designed website
										allow users to Shop by Brand.</p>
								</div>
							</div>
						</a>
					</div>
				</div>

				<div class="col-sm-6 col-md-4">
					<div class="work-blog">
						<div class="portfolio-img-content">
							<img src="../../assets/images/port3.png"
							class="img-responsive work-pic">
						</div>
						<a>
							<div class="work-technologies" style="background:#467dd9;">

								<img src="../../assets/images/bootstrap.png"
									class="img-responsive">
								<img src="../../assets/images/php.png"
									class="img-responsive">
								<img src="../../assets/images/js.png"
									class="img-responsive">
							</div>

							<div class="project-description">
								<div class="outer-wrapper">
									<div class="icon 													blue																										">
										<img src="../../assets/images/mobile.png"
											class="img-responsive">
									</div>
								</div>
								<div class="description">
									<h5 class="feature-title">General store Application</h5>
									<p class="text-content">This is an eCommerce grocery app with online payment
										gateway &amp; responsive website. Application has fulfilled customers need in every
										possible way.</p>
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-6 col-md-4">
					<div class="work-blog">
						<div class="portfolio-img-content">
							<img src="../../assets/images/deligo1_htc.png"
							class="img-responsive work-pic">
						</div>
					
						<a>
							<div class="work-technologies" style="background:#FFC107;">

								<img src="../../assets/images/cordova.png"
									class="img-responsive">
								<img src="../../assets/images/php.png"
									class="img-responsive">
								<!-- <img src="https://www.angularminds.com/angular-minds-admin/uploads/project_tech_images/0873e44cd0bd82972ff168f5c879235e.png"
									class="img-responsive">
								<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_tech_images/c2622608567de7663dd75fdeff32ab17.png"
									class="img-responsive"> -->
							</div>

							<div class="project-description">
								<div class="outer-wrapper">
									<div class="icon yellow">
										<img src="../../assets/images/mobile.png"
											class="img-responsive">
									</div>
								</div>
								<div class="description">
									<h5 class="feature-title">Deligo App</h5>

									<p class="text-content">DeliGO is an online app that provides quick service of Local pickup of your parcel from your shop and deliver it to your transporter though a carrier which can be shared or private at most economical cost to you.
									</p>
								</div>
							</div>
						</a>
					</div>
				</div>
				<div class="col-sm-6 col-md-4">
					<div class="work-blog">
						<div class="portfolio-img-content">
							<img src="../../assets/images/mbt.png"
							class="img-responsive work-pic">
						</div>
						<a>
							 <div
								class="work-technologies" style="background:#dc4e44;">
								<img src="../../assets/images/php.png"
									class="img-responsive">
								<img src="../../assets/images/js.png"
									class="img-responsive">
								<img src="../../assets/images/bootstrap.png"
									class="img-responsive">
							</div>

							<div class="project-description">
								<div class="outer-wrapper">
									<div class="icon red																																							">
										<img src="../../assets/images/website.png"
											class="img-responsive">
									</div>
								</div>
								<div class="description">
									<h5 class="feature-title">Mahabaleshwar Taxi Booking</h5>
									<p class="text-content">This web application is design and develop for taxi union of  mahabaleshwar, Which helps to maintain all record of  taxi Drivers and Account information of Union. It is developed in php  using Laravel Framework.</p>
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!-- <div class="blue-design-summary">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <h3 class="inverse-text sub-title">As a web development company, We help brands educate, engage, and inspire their customers through gamut of online services and powerful designs</h3>
          
            </div>
        </div>
    </div>
</div> -->