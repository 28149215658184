import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bulk-sms',
  templateUrl: './bulk-sms.component.html',
  styleUrls: ['./bulk-sms.component.scss']
})
export class BulkSmsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
