
<div class="content-wrapper blue-banner">
	<div class="container">
		<div class="row">
			<div class="col-md-6 banner-tag">
				<h1 class="img-title head-title">Digital Marketing</h1>
				<h4 class="img-subtitle sub-title">We will help you to grow your Business with digital world. </h4>
				<br>
			</div>
			<div class="col-md-6 hidden-xs hidden-sm">
				<img src="../../assets/images/digitalM.jpg" class="img-responsive banner-img" alt="slider-1">
			</div>
		</div>
	</div>
</div>
<section class="white-section">
        <div class="container">
        <div class="row"> 	
            <div class="col-md-6 col-sm-6">
                <div class="about-img">
                    <img src="../../assets/images/Digital.jpg" class="img-responsive" alt="About dsBit">      
                </div>
            </div>
            <div class="col-md-6 col-sm-6">
                <div class="about-info best-info">
                    <h2 class="mission-txt main-title content-title">BEST FOR YOU</h2>
                    <h5 class="common-description block-title">Digitally Forward, Creative at Core</h5>
                    <p class="text-content">Digital Marketing is marketing one's business using digital technologies on the internet. You can leverage digital channels like search engines, social networking websites, email, etc to market your products/services with promotional marketing messages that resonate best with your target audience.
                        <br>
                        <br>Digital Marketing is the need of the hour, every business whether upcoming or established needs to have an online presence as 95% of the global population is online and after the advent of smart phones with Internet connections the number is increasing.
                        <br>
                        </p>            
                </div>
            </div>
            </div>
        </div>
  </section>
  <section class="screens-block gray-section" id="screens">
    <div class="container">
        <div class="row">
            <div class=" text-left col-md-10">
                <h3 class="digital-service-title feature-title">Let’s Check Our Services in Digital Marketing</h3>
                <br>
            </div>
            <div class="text col-md-12">
                <div class="text col-md-4">
                    
                    <div class="clearfix"></div>
                    <div class="five_serv hvr-float hvr-overline-from-center">
                        <img src="../../assets/images/seo.png" class="hvr-icon" alt="SEO">
                        <h3>SEO</h3>
                        <p class="text-content">Search Engine Optimization is the process with the help of which the website's visibility increases on a search engine...
                        </p>
                    </div>
                </div>
                <div class="text col-md-8 no-padding">
                    <div class="text col-md-6 ">
                        <div class="five_serv hvr-float hvr-overline-from-center">
                            <img src="../../assets/images/sem.png" class="hvr-icon" alt="SEM">
                            <h3>SEM</h3>
                            <p class="text-content">Search Engine Marketing is a form of advertising that enables you to rank higher on search engine by means of paid advertise... 
                            </p>
                        </div>
                    </div>
                    <div class="text col-md-6 ">
                        <div class="five_serv hvr-float hvr-overline-from-center">
                            <img src="../../assets/images/smo.png" class="hvr-icon" alt="SMO">
                            <h3>SMO</h3>
                            <p>Social Media Optimization helps generate publicity organically to increase awareness of a product or a service via the use of... 
                            </p>
                        </div>
                    </div>
                    <div class="text col-md-6 ">
                        <div class="five_serv hvr-float hvr-overline-from-center">
                            <img src="../../assets/images/smm.png" class="hvr-icon" alt="SMM">
                            <h3>SMM</h3>
                            <p class="text-content">Social Media Marketing is the most preferred form of advertising as it uses social media platforms and websites for pro... 
                            </p>
                        </div>
                    </div>
                    <div class="text col-md-6 ">
                        <div class="five_serv hvr-float hvr-overline-from-center">
                            <img src="../../assets/images/email_marketing.png" class="hvr-icon" alt="Email Marketing">
                            <h3>Email Marketing</h3>
                            <p class="text-content">It is a fast, flexible and cost-effective way to deliver promotional messages to both existing and new customers via email. It allows... 
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="benifit-section">

    <div class="benefits">
		<div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class="col-md-4 form-section wow slideInRight" style="visibility: visible;">
						<div class=" text-left">
							<br>
							<br>
							<h6 class="benifit-title block-title">Benefits of Digital Marketing:</h6> 
							<br>
							<p class="text-content">1. Ensures Online Business Survival
								<br>2. Improves Conversion Rates
								<br>3. Cost Effective
								<br>4. Ensures Higher Revenues
								<br>5. Earns People's Trust
								<br>6. Builds Brand Reputation
								<br>7. Real Time Results
								<br>8. Improves Your Outreach
								<br>9. Helps in Competing with Large Corporations
								<br>
							</p>
						</div>
					</div>
					<div class="col-md-4 bg_gray wow slideInLeft" style="visibility: visible;">
						<div class=" text-center">
							<br>
							<br>
							<h6 class="benifit-title">Without<br>Digital Marketing</h6> 
							<br>
							<img src="../../assets/images/without_seo.png" class="hvr-icon hvr-buzz img-responsive">
							<br>
							<br>
							<br>
						</div>
					</div>
					<div class="col-md-4 wow slideInLeft" style="visibility: visible;">
						<div class=" text-center">
							<br>
							<br>
							<h6 class="benifit-title">With<br>Digital Marketing</h6> 
							<br>
							<img src="../../assets/images/with_seo.png" class="hvr-icon hvr-buzz img-responsive">
						</div>
					</div>
				</div>
			</div>
		</div>
    </div>


</section>
<div class="blue-design-summary">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <h3 class="inverse-text sub-title">As a full service Digital Marketing Agency, we give solutions over Social Media Marketing, Search engine optimization (SEO), Branding, Graphic Design and promotion</h3>
                <h3 class="inverse-text"></h3>
            </div>
        </div>
    </div>
</div>