import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from  '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgHttpLoaderModule } from 'ng-http-loader';
// Import your library
import { SlickCarouselModule } from 'ngx-slick-carousel';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { MainComponent } from './main/main.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { WebDevelopmentComponent } from './web-development/web-development.component';
import { AppDevelopmentComponent } from './app-development/app-development.component';
import { SoftwareDevelopmentComponent } from './software-development/software-development.component';
import { SoftwareInternshipComponent } from './software-internship/software-internship.component';
import { DigitalMarketingComponent } from './digital-marketing/digital-marketing.component';
import { LogoDesignComponent } from './logo-design/logo-design.component';
import { OnlineTrainingComponent } from './online-training/online-training.component';
import { BulkSmsComponent } from './bulk-sms/bulk-sms.component';
import { WebPortfolioComponent } from './web-portfolio/web-portfolio.component';
import { AppPortfolioComponent } from './app-portfolio/app-portfolio.component';
import { LogoPortfolioComponent } from './logo-portfolio/logo-portfolio.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { RoboticsComponent } from './robotics/robotics.component';
import { CareerComponent } from './career/career.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MainComponent,
    AboutUsComponent,
    WebDevelopmentComponent,
    AppDevelopmentComponent,
    SoftwareDevelopmentComponent,
    SoftwareInternshipComponent,
    DigitalMarketingComponent,
    LogoDesignComponent,
    OnlineTrainingComponent,
    BulkSmsComponent,
    WebPortfolioComponent,
    AppPortfolioComponent,
    LogoPortfolioComponent,
    ContactUsComponent,
    RoboticsComponent,
    CareerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SlickCarouselModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot()
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
