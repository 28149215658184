<div class="content-wrapper banner-website-development">
	<div class="container">
		<div class="row">
			<div class="col-md-6 banner-tag">
				<h1 class="img-title head-title">Robotics</h1>
				<h4 class="img-subtitle sub-title">We provide IoT and Automation Solutions to improve your work productivity and business performance.</h4>
				<br>
			</div>
			<div class="col-md-6 hidden-xs hidden-sm">
				<img src="../../assets/images/iot.png" class="img-responsive" alt="IOT" style="padding-bottom: 45px;">
			</div>
		</div>
	</div>
</div>
<section class="web-section">
    <div class="container">
        <div class="row mb-70">
            <div class="col-md-6 col-sm-6">
            <div class="web-info">
              <h2 class="main-title content-title">Robotics</h2>
                <p class="common-description text-content">
                    At dsBit Business Solution we are a IT services company. We clearly understand the need for IoT in the company and provide IoT services and solutions. Our IoT services help companies build a connected business, simplify business operations, improve employee productivity, enrich the customer experience and take advantage of new business opportunities. 
                    <br><br>Our motto is to turn your devices into "smart devices" and turn your company into a <strong>"smart and connected company"</strong>. In this way, you can take advantage of the power of future technology and stay one step ahead of the competition. 
                    <br><br>Our extensive experience in providing resultsdriven business mobility solutions has supported us in many companies. We also serve as IoT service providers. We have full-stack IoT application developers and home automation experts.
                     They have indepth knowledge of emerging IoT platforms, including Salesforce IoT Cloud.
                    </p>
                    </div>
            </div>
            <div class="col-md-6 col-sm-6">             
                  <img src="../../assets/images/r7.jpg" class="img-responsive iot-img" alt="Web Design">              
            </div>
        </div>
    </div> 
</section>
<section  class="gray-section">
    <div class="container">
        <div class="row"> 	
            <div class="col-md-6 col-sm-6">
                <div class="about-img">
                    <img src="../../assets/images/iot1.png" class="img-responsive feature-img" alt="About dsBit">      
                </div>
            </div>
            <div class="col-md-6 col-sm-6">
                <div class="specialize-info">
                    <h2 class="main-title block-title">Below are the different Features of our IOT Application Development</h2>
                    <ul class="web-design-list text-content">
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Open Source</li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>High flexibility</li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Personalization (Customization)</li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Portability</li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Implementation</li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Quick and easy way to develop</li>
                    </ul>
                    
                </div>
            </div>
        </div>
    </div>
</section>
<!-- <div class="orange-design-summary">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <h3 class="inverse-text">As a web development company, We help brands educate, engage, and inspire their customers through gamut of online services and powerful designs</h3>
                <h3 class="inverse-text"></h3>
            </div>
        </div>
    </div>
</div> -->