
<ng-http-loader></ng-http-loader>
<div id="banner-contactus" class="content-wrapper">
	<div class="container contact-tag-wrapper">
		<div class="row">	
			<div class="col-sm-12">
				<h1 style="color:#fff;" class="head-title">Contact Us</h1>
			</div>
		</div>
	</div>
</div>
<div class="container"> 
    <div class="col-lg-12 text-center">
        <div class="section-title">
            <h2 class="section-title section-title-border sub-title"> We'd love to hear from you!
                Brief us your requirements below, and let's connect.</h2>
                <div class="contact-form-wrapper">
                    <div class="row">
                        <div class="col-md-3"></div>
                        <div class="col-md-6">
                            <form class="contact-form" [formGroup]="registerForm">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group form-icon-group"> 
                                            <i class="fa fa-user"></i>
                                            <input class="form-control"  formControlName="userName"
                                             name="userName"
                                            placeholder="Your name *" type="text" required=""> 
                                            <span class="text-danger"
                                            *ngIf="(registerFormControl.userName.touched || submitted) && registerFormControl.userName.errors?.required">
                                            Name is required
                                        </span>
                                        <span class="text-danger"
                                        *ngIf="(registerFormControl.userName.touched || submitted) && registerFormControl.userName.errors?.pattern">
                                        Only characters are allowed
                                        </span>
                                        <span class="text-danger"
                                        *ngIf="(registerFormControl.userName.touched || submitted) && registerFormControl.userName.errors?.maxlength">
                                        Only 50 characters are allowed
                                        </span>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group form-icon-group"> 
                                            <i class="fa fa-envelope"></i> 
                                            <input class="form-control" formControlName="userEmail"
                                             placeholder="Your email *"
                                             type="email" required=""> 
                                             <span class="text-danger"
                                             *ngIf="(registerFormControl.userEmail.touched || submitted) && registerFormControl.userEmail.errors?.required">
                                             Email is required
                                         </span>
                                         <span class="text-danger"
                                             *ngIf="registerFormControl.userEmail.touched && registerFormControl.userEmail.errors?.email">
                                             Enter a valid email address
                                         </span>
                                            </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group form-icon-group"> 
                                            <i class="fa fa-file-text"></i>
                                             <input class="form-control"  formControlName="userSub"
                                              placeholder="Your subject *"
                                              type="text" required=""> 
                                              <span class="text-danger"
                                              *ngIf="(registerFormControl.userSub.touched || submitted) && registerFormControl.userSub.errors?.required">
                                              Subject is required
                                          </span>
                                            </div>
                                    </div>
                                </div>
                                <div class="form-group form-icon-group"> 
                                    <i class="fa fa-pencil"></i> 
                                    <textarea class="form-control" formControlName="userMessage"
                                     placeholder="Your message *" 
                                     rows="10" required=""></textarea>
                                     <span class="text-danger"
                                     *ngIf="(registerFormControl.userMessage.touched || submitted) && registerFormControl.userMessage.errors?.required">
                                     Message is required
                                    </span>
                                     </div> 

                                    <!-- <input type="submit" value="Send email" class="btn btn-primary">
                                <div class="messages text-center"></div> -->
                                <div class="form-group">
                                    <button type="submit" class="btn btn-success contact-btn" (click)="onSubmit()">Submit</button>
                                </div>
                            </form>        
                        </div>
                    </div>
                </div>
    
            <div class="row" style="margin-bottom: 50px;">
                <div class="col-md-12 col-sm-4">
                    <div class="conatct-info-box">
                        <div class="inner-box">
                            <h3 class="glyphicon glyphicon-map-marker map-icon" style="font-size: 50px;color:#D94949;margin-bottom: 0px;"></h3>
                            <div class="divider-line"></div>
                            <p class="text-content"><b>Kolhapur Office Address:</b> dsBit(India) Solutions
                                3rd Floor, Bavadekar Complex, Peth-Vadgaon,Kolhapur,Maharashtra India
                                416112.<br></p>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-4 col-sm-4">
                    <div class="conatct-info-box">
                        <div class="inner-box">
                            <h3 class="glyphicon glyphicon-map-marker map-icon" style="font-size: 50px;color:#D94949;margin-bottom: 0px;"></h3>
                            <div class="divider-line"></div>
                            <p class="text-content"><b>Pune Branch Office Address:</b>dsBit (India )Solutions 8, Runwal Florentine,4B- Right Bhusari Colony,Kothrud, Pune-411038.call-<b>9766129140</b> <br></p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-4">
                    <div class="conatct-info-box">
                        <div class="inner-box">
                            <h3 class="glyphicon glyphicon-map-marker map-icon" style="font-size: 50px;color:#D94949;margin-bottom: 0px;"></h3>
                            <div class="divider-line"></div>
                            <p class="text-content"><b>Goa Branch Office Address:</b> Coming Soon.......!<br></p>
                        </div>
                    </div>
                </div> -->
            </div>
<!-- form content -->
            
            
        </div>
    </div>
</div>