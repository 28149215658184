<div class="content-wrapper blue-banner">
    <div class="container">
        <div class="row">
            <div class="col-md-6 banner-tag">
                <h1 class="img-title head-title">Bulk SMS</h1>
                <h4 class="img-subtitle sub-title">we deliver accessible reliable and innovative messaging solutions by delivering
                    quality service through our people, processes, products and platforms.</h4>
                <br>
            </div>
            <div class="col-md-6 hidden-xs hidden-sm">
                <img src="../../assets/images/bulksms2.png" class="img-responsive banner-img" alt="slider-1">
            </div>
        </div>
    </div>
</div>
<div class="section service">
    <div class="container" style= "padding-top: 60px">
    <div class=" row">
        <p>
            <!-- Start Service Icon 1 -->
        </p>
        <div class="col-md-3 col-sm-6 service-box service-center animated fadeIn delay-01" data-animation="fadeIn"
            data-animation-delay="01">
            <div class="service-icon">
                <i class="fa fa-envelope icon-large"></i>
            </div>
            <div class="service-content">
                <h4 class="feature-title">Bulk SMS</h4>
                <p>Our mass SMS administration permits you to rapidly connect with a large number of your clients,
                    prospects and staff inside seconds at the snap of a catch.
                </p>
            </div>
        </div>
        <p>
            <!-- End Service Icon 1 -->
        </p>
        <p>
            <!-- Start Service Icon 2 -->
        </p>
        <div class="col-md-3 col-sm-6 service-box service-center animated fadeIn delay-02" data-animation="fadeIn"
            data-animation-delay="02">
            <div class="service-icon">
                <i class="fa fa-phone-square icon-large"></i>
            </div>
            <div class="service-content">
                <h4 class="feature-title">Voice SMS</h4>
                <p>We also offering voice to your SMS, Now through us you can transmit your message vocally to numerous
                    clients/gatherings of people in India.
                </p>
            </div>
            <p></p>
        </div>
        <p>
            <!-- End Service Icon 2 -->
        </p>
        <p>
            <!-- Start Service Icon 3 -->
        </p>
        <div class="col-md-3 col-sm-6 service-box service-center animated fadeIn delay-03" data-animation="fadeIn"
            data-animation-delay="03">
            <div class="service-icon">
                <i class="fa fa-envelope-o icon-large"></i>
            </div>
            <div class="service-content">
                <h4 class="feature-title">International SMS</h4>
                <p>Our SMS gives you a chance to send SMS instant messages to a scope of systems in every nation.<br>
                    You can send straightforwardly
                </p>
            </div>
            <p></p>
        </div>
        <p>
            <!-- End Service Icon 3 -->
        </p>
        <p>
            <!-- Start Service Icon 4 -->
        </p>
        <div class="col-md-3 col-sm-6 service-box service-center animated fadeIn delay-04" data-animation="fadeIn"
            data-animation-delay="04">
            <div class="service-icon">
                <i class="fa fa-envelope-o icon-large"></i>
            </div>
            <div class="service-content">
                <h4 class="feature-title">Media Marketing </h4>
                <p>Would you like to improve your social media marketing? Are you up to date with the best social media
                    tips and tools?
                </p>
            </div>
            <p></p>
        </div>    
    </div>
</div>
<div class="blue-design-summary">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <h3 class="inverse-text sub-title">we deliver accessible reliable and innovative messaging solutions by delivering quality service through our people, processes, products and platforms.</h3>
                <h3 class="inverse-text"></h3>
            </div>
        </div>
    </div>
</div>