import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SpinnerVisibilityService } from 'ng-http-loader';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
public user:any={
  userName:'',
  userEmail:'',
  userMessage:'',
  userSub:''
}
 registerForm: FormGroup;
  submitted = false;
  constructor(private fb: FormBuilder,public http:HttpClient,private spinner: SpinnerVisibilityService) { 
    
  }

  ngOnInit(): void {
      this.registerForm = this.fb.group({
        userName: ['', [Validators.required,Validators.maxLength(50),Validators.pattern("^[a-zA-Z]+$")]],
        userEmail: ['', [Validators.required, Validators.email]],
        userMessage: ['', [Validators.required]],    
        userSub: ['', [Validators.required]],
    },
    );
  }
  get registerFormControl() {
   // console.log('this.registerForm.controls',this.registerForm.controls);
    return this.registerForm.controls;
  }
  onSubmit() {
    this.submitted = true;
    if (this.registerForm.valid) {
      this.spinner.show();
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      this.http.post('https://formspree.io/f/mvovvpvo',
        { name: this.registerForm.value.userName, replyto: this.registerForm.value.userEmail,subject:this.registerForm.value.userSub, message: this.registerForm.value.userMessage},
        { 'headers': headers }).subscribe(
          response => {
            //console.log('res',response);
            alert('Form submitted successfuly.');
            this.registerForm.reset();
            this.registerForm.value.userName='';
            this.registerForm.value.userEmail='';
            this.registerForm.value.userMessage='';
            this.registerForm.value.userSub='';
            this.submitted=false;
            this.spinner.hide();  
          },
          error=>{
            console.log('error',error);
          }
        );
      //console.log(this.registerForm.value);
    }
  }

}
