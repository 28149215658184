    <!-- Slider -->
    <!-- slider -->
<section>
  <div id="myCarousel" class="carousel slide carousel-fade"  data-ride="carousel">
    <div class="carousel-inner">
      <div class="item active">
        <div class="mask flex-center">
          <div class="container">
            <div class="row align-items-center slider-item">
              <div class="col-md-7 col-12 order-md-1 order-2">
                <h4 class="head-title">Web <br>
                  Development</h4>
                <p class="sub-title">We Provide You Advanced Web Development Solution With Latest Trends.
                 <br>
                  </p>
                <!-- <a > VIEW MORE </a> -->
               </div>
              <div class="col-md-5 col-12 order-md-2 order-1"><img src="../../assets/images/web-banner.png" class="mx-auto" alt="slide"></div>  
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="mask flex-center">
          <div class="container">
            <div class="row align-items-center slider-item">
              <div class="col-md-7 col-12 order-md-1 order-2">
                <h4 class="head-title">Mobile Application <br>
                  Development </h4>
                <p class="sub-title">Get ultimate Android experience with dsBit, a leading mobile application development company in India. <br>
              </div>
              <div class="col-md-5 col-12 order-md-2 order-1"><img src="../../assets/images/ios-development.png" class="mx-auto" alt="slide"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="item">
        <div class="mask flex-center">
          <div class="container">
            <div class="row align-items-center slider-item">
              <div class="col-md-7 col-12 order-md-1 order-2">
                <h4 class="head-title">Digital <br>
                  Marketing </h4>
                <p class="sub-title">We Will Help You To Grow Your Business In The Digital World.
                   <br>
                 </p>
              </div>
              <div class="col-md-5 col-12 order-md-2 order-1"><img src="../../assets/images/digitalM.jpg" class="mx-auto banner-img" alt="slide"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev"> <span class="carousel-control-prev-icon" aria-hidden="true"></span> <span class="sr-only">Previous</span> </a> <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next"> <span class="carousel-control-next-icon" aria-hidden="true"></span> <span class="sr-only">Next</span> </a> </div>
  <!--slide end-->    
</section>
      <!-- End Slider -->
      <!-- Services -->
      <section class="about-us-section">
          <div class="container about-container">
            <div class="row">
                <div class="col-md-6 col-sm-6">
                    <div class="about-info">
                        <h2 class="about-title content-title">About Us</h2>
                        <h5 class="main-content text-content">dsBit Solutions Creates Bright And Functional Software Development for Businesses and Individuals.</h5>
                        <p class="text-content">dsBit Solutions is a quickest Software Development Company situated in Pune, India,With branches Located at Goa,Kolhapur    Conveying an assortment of IT administrations like website Development, Custom Application Development, Programming Improvement, Internet Business Arrangements, SEO and Digital Marketing services for the customers around the world. We are resolved to give high calibre and inventive we administrations to our customers at a focused cost to keep up the long haul relationship.</p>
                        <span class="learn-btn"><a [routerLink]="['/aboutUs']"><span class="linkInner">Read More <i class="fa fa-chevron-right"></i></span></a></span>
                    </div>
                </div>
                <div class="col-md-6 col-sm-6">
                    <div class="about-img">
                        <img src="../../assets/images/about-img.png" class="img-responsive" alt="about us">
                        
                    </div>
                </div>
                </div>
          </div>
      </section>
      <div id="mobileapp">
        <div class="container-fluid strip-parent">
          <div class="row">
            <div class="container">
              <div class="row wrapper mobile-content">
                <div class="grey-strip hidden-xs"></div>
                <div class="col-sm-6 mobileapp-img">
                  <img class="img-responsive lazyloaded" data-src="../../assets/images/access-phone.png" alt="angularjs case study" src="../../assets/images/port1.png">
                </div>
                <div class="col-sm-6">
                  <br>
                  <h2 class="mobileapp-heading head-title">
                    Mobile Application Development, turn
                    on your ideas to Apps
                  </h2>
                  <p class="mobileapp-content last text-content">
                    dsBit is most reliable mobile app development company in India. 
                    We hire developers have in-depth knowledge of app development for iOS, 
                    Android. We are ready to build your apps!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- services -->
      <div class="container service-wrapper">     
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center animated animatedFadeInUp fadeInUp" style="margin-top:40px;">
                <h5 class="section-title-sm sub-title">Best Service</h5>
                <h2 class="section-title section-title-border content-title">Service We Provide</h2>
            </div>
        </div>
        <div class="row service-row d-flex">
            <div class="col-md-3 d-flex ftco-animate">
                <div class="blog-entry align-self-stretch service-card" [routerLink]="['/web-development']">
                  <a class="service-img rounded" style="background-image: url('../../assets/images/webD-icon.png');">
                  </a>
                  <div class="text p-4 text-center description-wrapper ">
                      <div class="service-title mb-2">
                      <div class="title-txt feature-title"><a >Website Development</a></div>      
                    </div>
                    <h3 class="heading text-content"><a >dsBit Business Solution offers unique capabilities in the development of ecommerce websites in India, from start-ups to established companies. With our flexible e-commerce packages, you pay only for the features you want.</a></h3>
                  </div>
                </div>
                <a class="btn btn-secondary translateY-25" [routerLink]="['/web-development']">Read More</a>
              </div>
              <div class="col-md-3 d-flex ftco-animate">
                <div class="blog-entry align-self-stretch service-card" [routerLink]="['/app-development']">
                  <a class="service-img rounded" style="background-image: url('../../assets/images/mobile-app-icon.png');">
                  </a>
                  <div class="text p-4 text-center description-wrapper">
                      <div class="service-title mb-2">
                      <div class="title-txt feature-title"><a >Mobile App Development</a></div>
                    </div>
                    <h3 class="heading text-content"><a >We offer an impressive range of mobile apps for both Android and IOS. Good graphics and delightful UI/UX are hallmarks of our mobile apps. Our designs are robust and scalable. The form factor is optimized to fit most devices. Mobile apps are rich in features yet affordable. We have several clients to vouch for quality of our mobile apps.</a></h3>
                  </div>
                 
                </div>
                <a  class="btn btn-secondary translateY-25" [routerLink]="['/app-development']">Read More</a>
              </div>
              <div class="col-md-3 d-flex ftco-animate">
                <div class="blog-entry align-self-stretch service-card" [routerLink]="['/software-development']">
                  <a class="service-img rounded" style="background-image: url('../../assets/images/e-commece-icon.png');">
                  </a>
                  <div class="text p-4 text-center description-wrapper">
                      <div class="service-title mb-2">
                      <div class="title-txt feature-title"><a >Software Development</a></div>
                     
                    </div>
                    <h3 class="heading text-content"><a >We at dsBit, provide a full range of software development services, covering the entire product lifecycle from needs analysis and system architecture, through design, development, and quality assurance, up to and including long-term maintenance.</a></h3>
                  </div>
                  
                </div>
                <a class="btn btn-secondary translateY-25" [routerLink]="['/software-development']">Read More</a>
              </div>
              <div class="col-md-3 d-flex ftco-animate">
                <div class="blog-entry align-self-stretch service-card" [routerLink]="['/digital-marketing']">
                  <a class="service-img rounded" style="background-image: url('../../assets/images/digital-marketing-icon.png');">
                  </a>
                  <div class="text p-4 text-center description-wrapper">
                      <div class="service-title mb-2">
                      <div class="title-txt feature-title"><a >Digital Marketing</a></div>
                      
                    </div>
                    <h3 class="heading text-content"><a >We offer Search engine marketing, Social Media Marketing like Facebook, Twitter, Instagram, LinkedIn , Google Ads,and BulkSms Services AS well.</a></h3>
                  </div>
                  
                </div>
                <a class="btn btn-secondary translateY-25" [routerLink]="['/digital-marketing']">Read More</a>
              </div>
        </div>
        <div class="row service-row d-flex">
            <div class="col-md-3 d-flex ftco-animate">
                <div class="blog-entry align-self-stretch service-card" [routerLink]="['/software-internship']">
                  <a class="service-img rounded" style="background-image: url('../../assets/images/internship-icon.png');">
                  </a>
                  <div class="text p-4 text-center description-wrapper">
                      <div class="service-title mb-2">
                      <div class="title-txt feature-title">
                        <a>Internship</a></div>
                      
                    </div>
                    <h3 class="heading text-content"><a >At dsBit Solutions  we provide industrial training / Internship to BE, MCA, MCS and IT Students. We specialize in Mobile technologies e.g. Android, iOS and Web technologies Angular, JavaScript, BootStrap.
                      At dsBit Solutions, We have the special infrastructure for internship students with mentors allocated.</a></h3>
                  </div>
                  
                </div>
                <a class="btn btn-secondary translateY-25" [routerLink]="['/software-internship']">Read More</a>
              </div>
              <div class="col-md-3 d-flex ftco-animate">
                <div class="blog-entry align-self-stretch service-card" [routerLink]="['/logo-design']">
                  <a class="service-img rounded" style="background-image: url('../../assets/images/branding-icon.png');">
                  </a>
                  <div class="text p-4 text-center description-wrapper">
                      <div class="service-title mb-2">
                      <div class="title-txt feature-title"><a >Logo Design</a></div>
                     
                    </div>
                    <h3 class="heading text-content"><a >Logo fills in as a minute conspicuous confirmation of a brand and its things. This is in light of the fact that the trust the customer.</a></h3>
                  </div>
                  
                </div>
                <a class="btn btn-secondary translateY-25" [routerLink]="['/logo-design']">Read More</a>
              </div>
              <div class="col-md-3 d-flex ftco-animate">
                <div class="blog-entry align-self-stretch service-card" [routerLink]="['/online-training']">
                  <a class="service-img rounded" style="background-image: url('../../assets/images/seo-icon.png');">
                  </a>
                  <div class="text p-4 text-center description-wrapper">
                      <div class="service-title mb-2">
                      <div class="title-txt feature-title"><a >Online Training</a></div>
                      
                    </div>
                    <h3 class="heading text-content">We provide leading online learning platform that helps anyone learn business, software, technology and creative skills to achieve personal and professional goals. Through individual, corporate, academic and government subscriptions, members have access to the dsBit's video library of engaging, top-quality courses taught by recognized industry experts.

                      For 20 years, dsBit has helped students, leaders, IT and design pros, project managers—anyone in any role—develop software, creative, and business skills.<a ></a></h3>
                  </div>
                 
                </div>
                <a class="btn btn-secondary translateY-25" [routerLink]="['/online-training']">Read More</a>
              </div>
              <div class="col-md-3 d-flex ftco-animate">
                <div class="blog-entry align-self-stretch service-card" [routerLink]="['/robotics']">
                  <a class="service-img rounded robo-img" style="background-image: url('../../assets/images/robo.png');">
                  </a>
                  <div class="text p-4 text-center description-wrapper">
                      <div class="service-title mb-2">
                      <div class="title-txt feature-title"><a >Roboticas</a></div>
                    </div>
                    <h3 class="heading text-content"><a >dsBit Technologies specializes in providing solutions in trading of industrial automation and robotics spares also providing repairing and maintenance services in the same vertical. dsBit Technologies supplies a full range of automation products to help our customer meet their goals. Our highly qualified staffs are continuously trained in today's evolving standards, platforms and technologies. </a></h3>
                  </div>    
                </div>
                <a class="btn btn-secondary translateY-25" [routerLink]="['/robotics']">Read More</a>
              </div>
        </div>
     </div>
     <!-- technology -->
     <div id="responsive-tab" class="inverse-text">
      <div class="container-fluid">
        <div class="row">  
          <div class="col-sm-4 blue-bg">
            <h3 class="sub-title">Ionic Platform</h3>
            <p class="text-content">
              We have build amazing mobile apps on Ionic platform. Our ionic developers create 
              mobile apps with web technologies you want.
            </p>
          </div>
          <div class="col-sm-4 green-bg">
            <h3 class="sub-title">AngularJS and Ionic</h3>
            <p class="text-content">
              Ionic utilizes AngularJS in order to create a powerful SDK, those are most suited to develop 
              rich and robust applications.
            </p>
          </div>
          <div class="col-sm-4 red-bg">
            <h3 class="sub-title">Android Development </h3>
            <p class="text-content">
              Android is modeled on popular native mobile 
              development SDKs. Push it through Cordova or Phonegap when it's ready.
            </p>
          </div>
        </div>
      </div>
    </div>

     <!-- our clients -->
    <section class="ftco-section testimony-section bg-light">
    	<!-- <div class="overlay"></div> -->
      <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center animated animatedFadeInUp fadeInUp" style="margin-top:40px;">
                <!-- <h5 class="section-title-sm"></h5> -->
                <h2 class="section-title section-title-border content-title">Our Client testimonials</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12" data-wow-delay="0.05s">
                <div class="carousel slide" data-ride="carousel" id="quote-carousel">
                    <!-- Bottom Carousel Indicators -->
                    <ol class="carousel-indicators">
                        <li data-target="#quote-carousel" data-slide-to="0" class="active">
                          <img class="img-responsive " src="../../assets/images/shraddhasir.jpg" alt="">
                        </li>
                        <li data-target="#quote-carousel" data-slide-to="1">
                          <img class="img-responsive" src="../../assets/images/sandipP.jpg" alt="">
                        </li>
                        <li data-target="#quote-carousel" data-slide-to="2">
                          <img class="img-responsive" src="../../assets/images/sachinT.jpg" alt="">
                        </li>
                    </ol>
                    <!-- Carousel Slides / Quotes -->
                    <div class="carousel-inner text-center">
                        <!-- Quote 1 -->
                        <div class="item active">
                            <blockquote>
                                <div class="row">
                                    <div class="col-sm-8 col-sm-offset-2">

                                        <p class="text-content" style="font-size: 16px !important;">"When you choose dsBit you get a wonderful, professional team with innovative ideas, awesome customer service, and exactly what you’re looking for. dsBit took the ideas that we had and put them perfectly on the web and app as well!."</p>
                                        <small class="text-content">Dattatray Jadhav,&nbsp;Director,&nbsp;Shradha Vadhuvar</small>
                                    </div>
                                </div>
                            </blockquote>
                        </div>
                        <!-- Quote 2 -->
                        <div class="item">
                            <blockquote>
                                <div class="row">
                                    <div class="col-sm-8 col-sm-offset-2">
                                        <p class="text-content" style="font-size: 16px !important;">"dsBit team not only created our vision but provided guidance and insight throughout, leaving us with a finished product better than we had hoped. The team has continued to provide assistance and instruction after the release whenever we need it. The site looks and functions beautifully and has most certainly had a positive impact on our business!." </p>
                                        <small class="text-content">Sandip Patil,&nbsp;CEO ShreeSidhi Pvt. Ltd.</small>
                                    </div>
                                </div>
                            </blockquote>
                        </div>
                        <!-- Quote 3 -->
                        <div class="item">
                            <blockquote>
                                <div class="row">
                                    <div class="col-sm-8 col-sm-offset-2">
                                        <p class="text-content" style="font-size: 16px !important;">"I’ve worked with dsBit Design for about 5 years- they have built and maintained many websites for clients of mine. Their communication, follow-through and development knowledge is unbeatable."</p>
                                        <small class="text-content">Sachin Thorat,&nbsp;Bhavani Agro</small>
                                    </div>
                                </div>
                            </blockquote>
                        </div>
                    </div>

                    <!-- Carousel Buttons Next/Prev -->
                    <a data-slide="prev" href="#quote-carousel" class="left carousel-control"><i class="fa fa-chevron-left"></i></a>
                    <a data-slide="next" href="#quote-carousel" class="right carousel-control"><i class="fa fa-chevron-right"></i></a>
                </div>
            </div>

         </div>
      </div>
    </section>
    
    <!--banner  -->
    <section _ngcontent-ymg-c19="" class="section">
      <div _ngcontent-ymg-c19="" class="background-overlay banner-bg"></div>
      <div _ngcontent-ymg-c19="" class="container container-vertical-middle">
        <div _ngcontent-ymg-c19="" class="row vertical-middle">
          <div _ngcontent-ymg-c19="" class="col-md-10">
            <h2 _ngcontent-ymg-c19="" data-os-animation="fadeInLeft" data-os-animation-delay="0s" class="head-title c1 text-center element-top-60 element-bottom-60 text-light os-animation big light animated fadeInLeft" style="animation-delay: 0s;"> Interested in our full business solutions? </h2>
           </div>
           <div _ngcontent-ymg-c19="" class="col-md-2 text-center">
             <a _ngcontent-ymg-c19="" data-os-animation="none" data-os-animation-delay="0s" class="text-content c1 btn btn-primary btn-lg text-light btn-icon-right element-top-60 element-bottom-60" ng-reflect-router-link="/contact-us" [routerLink]="['/contact-us']"> Contact Us <i _ngcontent-ymg-c19="" data-animation="tada" class="fa fa-comment-o"></i></a>
           </div>
         </div>
       </div>
     </section>
     <!-- footer -->

                   