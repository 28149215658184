<div id="banner-contactus" class="content-wrapper">
	<div class="container contact-tag-wrapper">
		<div class="row">	
			
		</div>
	</div>
</div>

<section class="web-section">
    <div class="container">
        <div class="row mb-70">
            <div class="col-md-6 col-sm-6">
            <div class="web-info">
              <h2 class="main-title content-title">FIND YOUR FUTURE</h2>
                <p class="common-description text-content">We confide in offering quality to the customer through our organizations. Our organization is not just suggested for using rather we acknowledge inside,
                     giving the customer something that the customer would love for a long time.
                    Exciting challenges lie ahead—new regions, technologies, and businesses. Guided by our four core values, 
                    we’ll meet these challenges creatively and with the support of our expert team. Join us!.</p>           
                </div>
            </div>
            <div class="col-md-6 col-sm-6">             
                  <img src="../../assets/images/career.jpg" class="img-responsive career-img" alt="Carrer">              
            </div>
        </div>
    </div> 
</section>
      <!-- services -->
    <div class="container service-wrapper">     
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center animated animatedFadeInUp fadeInUp" style="margin-top:40px;">
                <h2 class="section-title section-title-border content-title">CURRENT JOB OPENINGS AND OPPORTUNITIES </h2>
            </div>
        </div>
        <div class="row justify-content-center">
           <div class="col-lg-12">
                <div class="tabs">
                  <div class="tab">
                    <input type="checkbox" id="chck1">
                    <label class="tab-label feature-title" for="chck1">Job Profile: Ionic Developer </label>
                    <div class="tab-content">
                      <div class="panel-body">
                        <div class="fluid-row">
                        <div class="row job-profile-class">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"><b>Company Name</b></div>
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">:  dsBit Solutions</div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"><b>Job Location</b></div>
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">:  Kolhapur, Maharashtra</div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"><b>Number of Openings</b></div>
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">:  1</div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"><b>Relevant Experience Required</b></div>
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">:  1- 2 year</div>
                        </div>
                        
                        <hr>
                        <div class="key-skills">
                        <h3>Key skills required for the job are :</h3>
                        <ul class="job-req-skill">
                        <li>Good exposure to design and development of the hybrid mobile app using Ionic Framework and Cordova.</li>
                        <li>Good understanding of Angular JS and jQuery.</li>
                        <li>Must have knowledge of HTML5, CSS, Bootstrap, Javascript.</li>
                        <li>Good understanding of REST APIs, web services.</li>
                        <li>Should know firebase or firestore, also connectivity with Ionic and AngularJS.</li>
                        </ul>
                        </div>
                        <!-- <div class="ca-pop-up-class">                     
                        <button type="button" class="btn btn-info btn-lg popup-btn-class" data-toggle="modal" data-target="#myModal">Submit Application</button>
                        </div> -->
                        </div>
                        </div>
                    </div>
                  </div>
                  <div class="tab">
                    <input type="checkbox" id="chck2">
                    <label class="tab-label feature-title"  for="chck2">Job Profile: Angular Developer </label>
                    <div class="tab-content">
                      <div class="panel-body">
                        <div class="fluid-row">
                        <div class="row job-profile-class">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"><b>Company Name</b></div>
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">:  dsBit Solutions</div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"><b>Job Location</b></div>
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">:  Kolhapur, Maharashtra</div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"><b>Number of Openings</b></div>
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">:  1</div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"><b>Relevant Experience Required</b></div>
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">:  1- 2 year</div>
                        </div>
                        
                        <hr>
                        <div class="key-skills">
                        <h3>Key skills required for the job are :</h3>
                        <ul class="job-req-skill">
                        <li>Strong knowledge of Angular, HTML5, CSS3, ES5 and Core JavaScript.</li>
                        <li>Well-versed with standard coding practices related to HTML5, CSS3, Core JavaScript and Angular.</li>
                        <li>Familiar with readable and reusable code implementation.</li>
                        <li>Good understanding of REST APIs, web services.</li>
                        <li>Should know firebase or firestore, also connectivity with Ionic and AngularJS.</li>
                        </ul>
                        </div>
                        </div>
                      </div>
                  </div>
                  </div>

                  <div class="tab">
                    <input type="checkbox" id="chck3">
                    <label class="tab-label feature-title"  for="chck3">Job Profile: PHP Developer </label>
                    <div class="tab-content">
                      <div class="panel-body">
                        <div class="fluid-row">
                        <div class="row job-profile-class">
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"><b>Company Name</b></div>
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">:  dsBit Solutions</div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"><b>Job Location</b></div>
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">:  Kolhapur, Maharashtra</div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"><b>Number of Openings</b></div>
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">:  1</div>
                        <div class="col-lg-4 col-md-4 col-sm-4 col-xs-12"><b>Relevant Experience Required</b></div>
                        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12">:  1- 2 year</div>
                        </div>
                        
                        <hr>
                        <div class="key-skills">
                        <h3>Key skills required for the job are :</h3>
                        <ul class="job-req-skill">
                        <li>Good database concepts and schema design skills.</li>
                        <li>Sound knowledge of PHP, MySQL, HTML, CSS, Javascript, AJAX.</li>
                        <li>Experience with XML technologies.</li>
                        <li>Sound understanding of the 3 tier web architecture.</li>
                        <li>Familiarity with API based programming is a plus.</li>
                        </ul>
                        </div>
                        </div>
                      </div>
                  </div>
                  </div>
                </div>
           </div>
        </div>
    </div>
        