<div class="navigation">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light bg-light">
            <a class="navbar-brand" href="https://dsbit.in/">
                <img src="../../assets/images/logo.png" alt="logo">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item" routerLink="" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
                        <a class="nav-link" [routerLink]="['']" aria-haspopup="true">
                            Home
                        </a>
                    </li>
                    <li class="nav-item" [routerLinkActive]="['active']">
                        <a class="nav-link" aria-haspopup="true" [routerLink]="['/aboutUs']">
                            About Us
                        </a>
                    </li>

                    <li class="nav-item dropdown" [routerLinkActive]="['active']">
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            Services
                        </a>
                        <div class="dropdown-menu">
                            <a class="dropdown-item" [routerLink]="['/software-development']">Software Development</a>
                            <a class="dropdown-item" [routerLink]="['/web-development']">Website Development</a>
                            <a class="dropdown-item" [routerLink]="['/app-development']">Mobile Application
                                Development</a>
                            <a class="dropdown-item" [routerLink]="['/digital-marketing']">Digital Marketing</a>
                            <a class="dropdown-item" [routerLink]="['/logo-design']">Logo Design</a>
                            <a class="dropdown-item" [routerLink]="['/software-internship']">Internship</a>
                            <a class="dropdown-item" [routerLink]="['/online-training']">Technical Training</a> 
                            <a class="dropdown-item"[routerLink]="['/robotics']">Robotics</a>
                        </div>
                    </li>
                    <li class="nav-item" [routerLinkActive]="['active']">
                        <a class="nav-link" role="button" aria-haspopup="true" [routerLink]="['/web-portfolio']">
                            Portfolio
                        </a>
                    </li>
                    <li class="nav-item" [routerLinkActive]="['active']">
                        <a class="nav-link" role="button" aria-haspopup="true" [routerLink]="['/bulk-sms']">
                            Bulk SMS
                        </a>
                    </li>
                    <li class="nav-item" [routerLinkActive]="['active']">
                        <a class="nav-link" role="button" aria-haspopup="true" [routerLink]="['/career']">
                            Career
                        </a>
                    </li>
                    <li class="nav-item" [routerLinkActive]="['active']">
                        <a class="nav-link" role="button" aria-haspopup="true" [routerLink]="['/contact-us']">
                            Contact Us
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</div>
<router-outlet (activate)="onActivate($event)"></router-outlet>
<footer id="footer">
    <section class="section">
        <div class="container">
            <div class="row footer-columns-4" style="margin-top: 40px!important;
            margin-bottom: 40px;">
                <div class="col-sm-2">
                    <div class="sidebar-widget widget_categories">
                        <h3 class="sidebar-header">Links</h3>
                        <ul class="menu-list">
                          <li><a [routerLink]="['']" class="text-content">Home</a></li>
                          <li><a [routerLink]="['/aboutUs']" class="text-content">About Us</a></li>
                          <li><a [routerLink]="['/web-portfolio']" class="text-content">Portfolio</a></li>
                          <li><a [routerLink]="['/bulk-sms']" class="text-content">Bulk SMS</a></li>  
                          <li><a [routerLink]="['/career']" class="text-content">Career</a></li>    
                          <li><a [routerLink]="['/contact-us']" class="text-content">Contact Us</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="sidebar-widget woocommerce widget_product_tag_cloud">
                        <h3 class="sidebar-header">Services</h3>
                        <div class="tagcloud">
                            <ul>
                                <li> <a class="text-content" [routerLink]="['/software-development']" 
                                    style="font-size: 11.5pt;" title="4 topics">Software Development</a> </li>
                                <li> <a class="text-content" [routerLink]="['/web-development']"
                                     style="font-size: 16.75pt;" title="6 topics">Web Development</a> </li>
                                <li> <a class="text-content" [routerLink]="['/app-development']" 
                                    style="font-size: 16.75pt;" title="6 topics">Mobile App Development</a> </li>
                                <li> <a class="text-content" [routerLink]="['/bulk-sms']" 
                                    style="font-size: 11.5pt;" title="4 topics">Bulk SMS</a> </li>
                                <li> <a class="text-content" [routerLink]="['/digital-marketing']"
                                     style="font-size: 11.5pt;" title="4 topics">Digital Marketing</a> </li>
                                <li> <a class="text-content"[routerLink]="['/logo-design']"
                                     style="font-size: 11.5pt;" title="4 topics">Logo Design</a> </li>
                                     <li> <a class="text-content" [routerLink]="['/online-training']"
                                        style="font-size: 11.5pt;" title="4 topics">Technical Training</a> </li>
                            </ul>
                        </div>
                        
                    </div>
                </div>
                
                <div class="col-sm-6">
                    <div class="sidebar-widget widget_text">
                        <h3 class="sidebar-header">Our Headquarters</h3>
                        <div class="textwidget">
                            <iframe class="content-corner" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15275.776904618522!2d74.29596501893946!3d16.82912277571871!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xad9b810158976ce7!2sRBL%20Bank%20Ltd%20-%20Peth%20Vadgaon%2C%20Kolhapur%20Branch%20%26%20ATM!5e0!3m2!1sen!2sin!4v1588440395339!5m2!1sen!2sin" width="100%" height="200" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" ></iframe>          
                         
                            <p class="text-content" style="color: white;"><span class="text-content" style="color: #ef7f1a"> <b>Branch Office Address:</b></span> dsBit(India) Solutions, 3rd Floor, Bavadekar Complex, Peth-Vadgaon, Kolhapur, Maharashtra India. 416112</p>
                            <p class="text-content" style="color: white;"><span class="text-content" style="color: #ef7f1a"> <b>Email : </b></span> <a>infodsbit@gmail.com</a><span class="text-content" style="color: #ef7f1a"> </span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</footer>