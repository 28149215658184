<div id="banner-contactus" class="content-wrapper">
	<div class="container contact-tag-wrapper">
		<div class="row">
			<div class="col-sm-12 col-md-12">
                <h1 style="color:#fff;" class="head-title">Technical Training</h1>
                <h4 class="img-subtitle sub-title">You dream it we built it.</h4>
			</div>
		</div>
	</div>
</div>
<section class="web-section">
    <div class="container">
        <div class="row mb-70">
            <div class="col-md-6 col-sm-6">
            <div class="web-info">
              <h2 class="main-title content-title">Technical Training</h2>
                <p class="common-description text-content">
                    dsBit Training Institute in Pune,Kolhapur is a pioneer in training Institutes providing various(online/offline) professional training courses for multiple technologies. Proficient professionals and experts constitute an institute. We are working on technological domains as a centre for education to give a comprehensive and most exceptional learning experience in various technologies. dsBit  is the Leading Professional Training Institute for java ,Advance Java,Android,php,AngularJS, MVC, Python, Salesforce, Data Science, AI, Blockchain Hadoop, RPA (Blue Prism, Automation Anywhere, and UI Path) Informatics, Selenium. Our Specialty is that we provide Hands-on Training for all Courses, and All Trainers at our Institute are from MNC’s.
                </div>
            </div>
            <div class="col-md-6 col-sm-6">             
                  <img src="../../assets/images/howitwork-image.png" class="img-responsive" alt="Web Design">              
            </div>
        </div>
    </div> 
</section>
<!-- technology -->
<div class="iot-overview">
    <div class="container">
        <h2 class="content-title text-center service-title">
            Technologies that empowers <br class="hidden-xs">Our web and mobile
            application development services
        </h2>

  
      <div class="row text-center">
        <div class="col-sm-4 col-xs-6">
            <div class="shadow-box">
                 <span style="background: #E52A3A;">
                <i class="sprite sprite-angularjs"></i>
            </span>
            <h5 class="feature-title">Angular</h5>
            <p class="text-content">
                AngularJS is a JavaScript framework and perfect for <a [routerLink]="['/web-development']" class="visit-website"> Single Page
                    Applications.</a> It lets you extend HTML vocabulary for your
                application.
            </p>
            </div>
           
        </div>
        <div class="col-sm-4">
          <div class="shadow-box">
            <span style="background: #4e8ef7;">
                <i class="sprite sprite-ionic"></i>
            </span>
            <h5 class="feature-title">Ionic Framework</h5>
            <p class="text-content">
                Bootstrap, a sleek, intuitive, most popular HTML, CSS, and
                JavaScript framework for developing responsive, mobile-first web
                sites that scales your websites and applications from phones to
                tabl
          </div>
        </div>
        <div class="col-sm-4">
          <div class="shadow-box">
            <span style="background: #E34E26;">
                <i class="sprite sprite-html5"></i>
            </span>
            <h5 class="feature-title">HTML5 &amp; CSS3</h5>
            <p class="text-content">
                HTML5 is more enhanced from HTML with many new Semantic and
                multimedia Elements. CSS3 is the latest standard for CSS that
                brings a lot of long-awaited novelties, like rounded corners,
                shadows, gradients, transitions.
            </p>
          </div>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-sm-4">
          <div class="shadow-box">
            <span style="background: #5C427E;">
                <i class="sprite sprite-bootstrap"></i>
            </span>
            <h5 class="feature-title">Bootstrap</h5>
            <p class="text-content">
                Bootstrap, a sleek, intuitive, most popular HTML, CSS, and
                JavaScript framework for developing responsive, mobile-first web
                sites that scales your websites and applications from phones to
                tablets to desktops.
            </p>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="shadow-box">
            <span style="background: #0768AD;">
                <i class="sprite sprite-jquery"></i>
            </span>
            <h5 class="feature-title">jQuery</h5>
            <p class="text-content">
                jQuery is designed to simplify the client-side scripting of
                HTML. The set of jQuery core features make html elements
                selections, traversal and manipulation easy. One can create
                animations, handle events using Jquery.
            </p>
          </div>
        </div>
        <div class="col-sm-4">
          <div class="shadow-box">
            <span style="background: #6C7EB7;">
                <i class="sprite sprite-php"></i>
            </span>
            <h5 class="feature-title">PHP</h5>
            <p class="text-content">
                PHP is a server-side scripting language designed for web
                development which can be simply mixed with HTML code, or it can
                be used in combination with various templating engines and web
                frameworks.
            </p>
          </div>
        </div>
      </div>
      <!-- row-->
    </div>
    <!-- container-->
  </div>
<section  class="gray-section">
    <div class="container">
        <div class="row"> 	
            <div class="col-md-6 col-sm-6">
                <div class="about-img">
                    <img src="../../assets/images/train1.png" class="img-responsive" alt="About dsBit">      
                </div>
            </div>
            <div class="col-md-6 col-sm-6">
                <div class="specialize-info">
                    <h2 class="main-title block-title">We provide following</h2>
                    <ul class="web-design-list text-content">
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>To provide candidates with knowledge that is at-par with the IT industry, but also in a
                             pocket friendly way so that everyone can take benefit of our courses.</li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>To provide training to candidates in various technologies by experts who have real-time industry knowledge
                             so that candidates can start their career and be in sync with the industry.</li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>bridge the gap between the corporate sector and candidates.
                            To provide corporate trainings to the companies as per their requirements.
                            </li>
                    </ul>
                    
                </div>
            </div>
        </div>
    </div>
</section>



<!-- <div class="blue-design-summary">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <h3 class="inverse-text">As a web development company, We help brands educate, engage, and inspire their customers through gamut of online services and powerful designs</h3>
                <h3 class="inverse-text"></h3>
            </div>
        </div>
    </div>
</div> -->