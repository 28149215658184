<div class="content-wrapper banner-website-development">
	<div class="container">
		<div class="row">
			<div class="col-md-6 banner-tag">
				<h1 class="img-title head-title">Mobile app Development</h1>
				<h4 class="img-subtitle sub-title">We Develop Smarter and Faster Mobile App with latest Trends.</h4>
				<br>
			</div>
			<div class="col-md-6 hidden-xs hidden-sm">
				<img src="../../assets/images/ios-development.png" class="img-responsive banner-img" alt="slider-1">
			</div>
		</div>
	</div>
</div>
<section class="web-section">
    <div class="container">
        <div class="row mb-70">
            <div class="col-md-6 col-sm-6">
            <div class="web-info">
              <h2 class="main-title content-title page-title">Mobile App Development</h2>
              <h3 class="block-title">We Develop Smarter and Faster Mobile App with latest Trends  </h3>
              <p class="common-description text-content">Mobile App development is a process of creating a software application for mobile devices like smart phones and tablets. It involves a series of steps to write software for mobile devices.
                <br>
                <br>At dsBit we ensure amalgamation of human brain and tech innovation while creating most adaptive and responsive solutions in mobility. Our core offerings include implementing modern innovative technologies to transform your business.
                <br>
                <br>We help you turn your ideas into reality, our experienced and dedicated team makes sure that you get the best results by enabling digital work flows and promising timely delivery.</p>
                </div>
            </div>
            <div class="col-md-6 col-sm-6">             
                  <img src="../../assets/images/Android-development.png" class="img-responsive app-design" alt="Web Design">              
            </div>
        </div>
    </div>
</section>
    <section class="gray-section feature-section">
        <div class="container">
			<div class="row">
				<div class="col-md-12">
					<div class=" text-center ">
						<h3 class="content-title">Exclusive Features</h3>
						<p class="text-content">Analyse, Plan, Implement and Optimize; this is what we follow. Our workflow is simple and takes care of all your marketing needs.</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-content">
						<div class="box_white1 hvr-bob text-content">
							<img src="../../assets/images/free_trial.png" alt="Free-Trial">
							<br>Free Trial</div>
						<div class="box_white1 hvr-bob text-content">
							<img src="../../assets/images/Unique_Design.png" alt="Unique Design">
							<br>Unique Design</div>
						<div class="box_white1 hvr-bob text-content">
							<img src="../../assets/images/Cross_Platform.png" alt="Cross-Platform">
							<br>Cross-Platform</div>
						<div class="box_white1 hvr-bob text-content">
							<img src="../../assets/images/User_Friendly.png" alt="User Friendly">
							<br>User Friendly</div>
						<div class="box_white1 hvr-bob text-content">
							<img src="../../assets/images/Mailer_Engine.png" alt="Mailer Engine">
							<br>Mailer Engine</div>
						<div class="box_white1 hvr-bob text-content">
							<img src="../../assets/images/Notifications.png" alt="Notifications">
							<br>Notifications</div>
						<div class="box_white1 hvr-bob text-content">
							<img src="../../assets/images/Free_Updates.png" alt="Free Updates">
							<br>Free Updates</div>
						<div class="box_white1 hvr-bob text-content">
							<img src="../../assets/images/Fast_Support.png" alt="Fast Support">
							<br>Fast Support</div>
                </div>
            </div>	
        </div>
</section> 
<!-- <section class="white-section">
    <div class="container">
        <div class="row mb-70">
            <div class="col-md-6 col-sm-6">
            <div class="web-info">
              <h2 class="main-title content-title">Mobile App Development</h2>
                <p class="common-description text-content">We ensure intuitive app design and development services. Our team builds a solution around specific requirements , budgets, and timelines of the clients. We have proven abilities in examining business challenges, understanding the client's ambitions and delivering compelling android solutions with clear value.</p>
                
                </div>
            </div>
            <div class="col-md-6 col-sm-6">             
                  <img src="../../assets/images/Android-development.png" class="img-responsive" alt="Web Design">              
            </div>
        </div>
    </div> 
</section> -->
<section  class="white-section">
    <div class="container">
        <div class="row"> 	
            <div class="col-md-6 col-sm-6">
                <div class="about-img">
                    <img src="../../assets/images/ios-development.png" class="img-responsive mobile-img" alt="About dsBit">      
                </div>
            </div>
            <div class="col-md-6 col-sm-6">
                <div class="app-specialize-info">
                    <h2 class="main-title block-title">We Specialize in the Following</h2>
                    <ul class="web-design-list text-content">
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Responsive and user friendly design</li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Easy navigation added for visitors</li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Help to grow a mailing list for more lead generation</li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Option to Add a coupon, promotion and offers</li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Option to add secured payment gateway</li>
                     </ul>
                    
                </div>
            </div>
        </div>
    </div>
</section>
<div class="orange-design-summary">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <h3 class="inverse-text sub-title">Our skilled Android app developers have built cutting edge android applications using latest framworks like React native, flutter, ionic, PhoneGap.</h3>
                <h3 class="inverse-text"></h3>
            </div>
        </div>
    </div>
</div>