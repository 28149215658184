<div class="content-wrapper banner-website-development">
	<div class="container">
		<div class="row">
			<div class="col-md-6 banner-tag">
				<h1 class="img-title head-title">Web Development</h1>
				<h4 class="img-subtitle sub-title">We Provide Advance web Development with Latest Technology.</h4>
				<br>
			</div>
			<div class="col-md-6 hidden-xs hidden-sm">
				<img src="../../assets/images/web-banner.png" class="img-responsive" alt="slider-1">
			</div>
		</div>
	</div>
</div>
<section class="web-section">
    <div class="container">
        <div class="row mb-70">
            <div class="col-md-6 col-sm-6">
            <div class="web-info">
              <h2 class="main-title content-title">Web Development</h2>
                <p class="common-description text-content">dsBit is a leading Website design company in Pune. We offering extensive range of web design services and also perfect Web and Mobile App Design services. We maintain high security and confidentiality of our customers and their data.We have no hidden costs or costs for website design and we are known in the industry for offering profitable services.We offer 24X7 technical support and ever ready to deliver real-time solution.</p>
                <p class="text-content">We place stock in broadening the advancement of our clients with unrivaled site change and imaginative blueprints.</p>             
                </div>
            </div>
            <div class="col-md-6 col-sm-6">             
                  <img src="../../assets/images/web4.jpg" class="img-responsive" alt="Web Design">              
            </div>
        </div>
    </div> 
</section>
<section  class="specialize-section">
    <div class="container">
        <div class="row"> 	
            <div class="col-md-6 col-sm-6">
                <div class="about-img">
                    <img src="../../assets/images/webdevelopment.png" class="img-responsive" alt="About dsBit">      
                </div>
            </div>
            <div class="col-md-6 col-sm-6">
                <div class="specialize-info">
                    <h2 class="main-title block-title">We Specialize in the Following</h2>
                    <ul class="web-design-list text-content">
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Responsive and user friendly design</li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Easy navigation added for visitors</li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Help to grow a mailing list for more lead generation</li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Option to Add a coupon, promotion and offers</li>
                        <li><i class="fa fa-chevron-right" aria-hidden="true"></i>Option to add secured payment gateway</li>
                     </ul>     
                </div>
            </div>
        </div>
    </div>
</section>
<div class="orange-design-summary">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <h3 class="inverse-text sub-title">As a web development company, We help brands educate, engage, and inspire their customers through gamut of online services and powerful designs.</h3>
                <h3 class="inverse-text"></h3>
            </div>
        </div>
    </div>
</div>