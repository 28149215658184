<div id="banner-our-work" class="content-wrapper">
	<div class="container">
		<div class="row">
			<div class="col-sm-8">
				<h1>Our Featured Work</h1>
				<h4>Committed to delivery custom web applications that converts your
					ideas into reality by adding value to your business!</h4>
				<br> <a href="javascript:;"
					class="modal-btn btn-normal btn-small btn-hover-shadow btn-bordered btn-filled">Talk
					to our experts</a>
			</div>
			<div class="col-sm-4 hidden-xs">
				<img class="portfolio-img img-responsive"
					src="https://www.angularminds.com/site_data/images/our-work/work-banner.png" alt="our-work">
			</div>
		</div>
	</div>
</div>
<div id="our-work">
	<div class="overview">
		<div class="container">
			<div class="row">
				<div class="col-sm-12">
					<h2>Helping to make the web a better world for business</h2>
					<p>At Angular Minds, we understand, design, plan, implement and
						achieve your project objectives with our latest technologies. We
						worked with a number of global businesses across diverse verticals
						and results into high quality software and solutions at an
						affordable cost and meeting strict deadlines. Angular Minds had
						achieved defined goals and results within deadline and budget.</p>
				</div>
			</div>
		</div>
	</div>

	<div class="container">
		<div id="portfolio_row">
			<div class="row">
				<div class="col-sm-6 col-md-4">
					<div class="work-blog">
						<img src="https://www.angularminds.com/angular-minds-admin/uploads/portfolio_images/3df454c9f4e5f58250ee694cb14fca6b.jpg"
							class="img-responsive work-pic">
						<a href="#" onclick="window.open('http://www.liveexamcenter.com')">http://www.liveexamcenter.com
							<div class="work-technologies" style="background:#dc4e44;">
								<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_tech_images/0642355bdfe100085757838459d73648.png"
									class="img-responsive">
								<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_tech_images/a875fb786ec3339416f303fc60e01f1e.png"
									class="img-responsive">
								<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_tech_images/0873e44cd0bd82972ff168f5c879235e.png"
									class="img-responsive">
								<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_tech_images/060f1a88a38392acf74e794956553ec1.png"
									class="img-responsive">
								<hr style="opacity: 0.4; margin-top: 5px; margin-bottom: 10px;" noshade="noshade">
								<span>www.liveexamcenter.com</span>
							</div>

							<div class="project-description">
								<div class="outer-wrapper">
									<div class="icon red">
										<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_category_images/844dd57c3b686e9ed37154c317dd95b8.png"
											class="img-responsive">
									</div>
								</div>
								<div class="description">
									<h5>Live Exam Center</h5>
									<p>Online Assessment App, Bringing Exams on Mobile. This app is published by ionic
										in their showcase. It is development on Cross Platform with Angular JS &amp;
										Ionic framework. </p>
								</div>
							</div>
						</a>
					</div>
				</div>

				<div class="col-sm-6 col-md-4">
					<div class="work-blog">
						<img src="https://www.angularminds.com/angular-minds-admin/uploads/portfolio_images/5af9b52b992c586d5d33d010586bb9ea.jpg"
							class="img-responsive work-pic">
						<a href="#" onclick="window.open('http://www.mfivebiz.com')">http://www.mfivebiz.com
							<div class="work-technologies" style="background:#467dd9;">
								<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_tech_images/a660883affcf9738d8ccfd1f96502fd7.png"
									class="img-responsive">
								<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_tech_images/0d8482e82bc6f0cca5b572dbdc29e549.png"
									class="img-responsive">
								<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_tech_images/a7f0e23c4a6a7ee91b87c444d98f648d.png"
									class="img-responsive">
								<hr style="opacity: 0.4; margin-top: 5px; margin-bottom: 10px;" noshade="noshade">
								<span>www.mfivebiz.com</span>
							</div>

							<div class="project-description">
								<div class="outer-wrapper">
									<div class="icon blue">
										<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_category_images/aea2a232a5d510902ad44d52e307b772.png"
											class="img-responsive">
									</div>
								</div>
								<div class="description">
									<h5>MFiveBizCon</h5>

									<p>MFiveBizCon is a management consulting firm. A beautiful Unique Responsive
										website. It is CMS site that allows customer to change client reviews, blogs,
										News &amp; Career's Post. </p>
								</div>
							</div>
						</a>
					</div>
				</div>
				<div class="col-sm-6 col-md-4">
					<div class="work-blog">
						<img src="https://www.angularminds.com/angular-minds-admin/uploads/portfolio_images/3df454c9f4e5f58250ee694cb14fca6b.jpg"
							class="img-responsive work-pic">
						<a href="#" onclick="window.open('http://www.liveexamcenter.com')">http://www.liveexamcenter.com
							<div class="work-technologies" style="background:#dc4e44;">
								<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_tech_images/0642355bdfe100085757838459d73648.png"
									class="img-responsive">
								<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_tech_images/a875fb786ec3339416f303fc60e01f1e.png"
									class="img-responsive">
								<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_tech_images/0873e44cd0bd82972ff168f5c879235e.png"
									class="img-responsive">
								<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_tech_images/060f1a88a38392acf74e794956553ec1.png"
									class="img-responsive">
								<hr style="opacity: 0.4; margin-top: 5px; margin-bottom: 10px;" noshade="noshade">
								<span>www.liveexamcenter.com</span>
							</div>

							<div class="project-description">
								<div class="outer-wrapper">
									<div class="icon red">
										<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_category_images/844dd57c3b686e9ed37154c317dd95b8.png"
											class="img-responsive">
									</div>
								</div>
								<div class="description">
									<h5>Live Exam Center</h5>
									<p>Online Assessment App, Bringing Exams on Mobile. This app is published by ionic
										in their showcase. It is development on Cross Platform with Angular JS &amp;
										Ionic framework. </p>
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-sm-6 col-md-4">
					<div class="work-blog">
						<img src="https://www.angularminds.com/angular-minds-admin/uploads/portfolio_images/7356a240ecd610e08b43d63c03595921.jpg"
							class="img-responsive work-pic">
						<a href="#" onclick="window.open('http://www.purpleattire.com')">http://www.purpleattire.com
							<div class="work-technologies" style="background:#FFC107;">

								<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_tech_images/a660883affcf9738d8ccfd1f96502fd7.png"
									class="img-responsive">
								<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_tech_images/0d8482e82bc6f0cca5b572dbdc29e549.png"
									class="img-responsive">
								<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_tech_images/0873e44cd0bd82972ff168f5c879235e.png"
									class="img-responsive">
								<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_tech_images/c2622608567de7663dd75fdeff32ab17.png"
									class="img-responsive">
								<hr style="opacity: 0.4; margin-top: 5px; margin-bottom: 10px;" noshade="noshade">
								<span>www.purpleattire.com</span>
							</div>

							<div class="project-description">
								<div class="outer-wrapper">
									<div class="icon 																																							yellow">
										<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_category_images/e4f5ab4d89c4148adfbd9e726f192991.png"
											class="img-responsive">
									</div>
								</div>
								<div class="description">
									<h5>Purple Attire</h5>

									<p>Buy Women's Indian Ethnic Wear Online, Purpleattire.com. This Responsive
										eCommerce website allow users to search for products by categories, price, etc.
									</p>
								</div>
							</div>
						</a>
					</div>
				</div>

				<div class="col-sm-6 col-md-4">
					<div class="work-blog">
						<img src="https://www.angularminds.com/angular-minds-admin/uploads/portfolio_images/e5dcf037a5364865fbafded05a402ede.jpg"
							class="img-responsive work-pic">
						<a href="#" onclick="window.open('http://shop.mapro.com')">http://shop.mapro.com <div
								class="work-technologies" style="background:#dc4e44;">

								<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_tech_images/a660883affcf9738d8ccfd1f96502fd7.png"
									class="img-responsive">
								<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_tech_images/0d8482e82bc6f0cca5b572dbdc29e549.png"
									class="img-responsive">
								<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_tech_images/a7f0e23c4a6a7ee91b87c444d98f648d.png"
									class="img-responsive">
								<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_tech_images/c2622608567de7663dd75fdeff32ab17.png"
									class="img-responsive">
								<hr style="opacity: 0.4; margin-top: 5px; margin-bottom: 10px;" noshade="noshade">
								<span>shop.mapro.com</span>
							</div>

							<div class="project-description">
								<div class="outer-wrapper">
									<div class="icon red																																							">
										<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_category_images/35026946025503fc586d43aaff0be5b7.png"
											class="img-responsive">
									</div>
								</div>
								<div class="description">
									<h5>Mapro</h5>

									<p>This is eCommerce website for Online Mapro Products Shopping. Beautifully
										designed website
										allow users to Shop by Brand. Also, users can quickly logged in with
										facebook/Google+</p>
								</div>
							</div>
						</a>
					</div>
				</div>

				<div class="col-sm-6 col-md-4">
					<div class="work-blog">
						<img src="https://www.angularminds.com/angular-minds-admin/uploads/portfolio_images/87c71a74dbc680038e7a05fa49d24b8c.jpg"
							class="img-responsive work-pic">
						<a href="#" onclick="window.open('http://www.womanscomfort.com')">http://www.womanscomfort.com
							<div class="work-technologies" style="background:#467dd9;">

								<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_tech_images/a660883affcf9738d8ccfd1f96502fd7.png"
									class="img-responsive">
								<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_tech_images/0d8482e82bc6f0cca5b572dbdc29e549.png"
									class="img-responsive">
								<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_tech_images/a7f0e23c4a6a7ee91b87c444d98f648d.png"
									class="img-responsive">
								<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_tech_images/c2622608567de7663dd75fdeff32ab17.png"
									class="img-responsive">
								<hr style="opacity: 0.4; margin-top: 5px; margin-bottom: 10px;" noshade="noshade">
								<span>www.womanscomfort.com</span>
							</div>

							<div class="project-description">
								<div class="outer-wrapper">
									<div class="icon 													blue																										">
										<img src="https://www.angularminds.com/angular-minds-admin/uploads/project_category_images/e4f5ab4d89c4148adfbd9e726f192991.png"
											class="img-responsive">
									</div>
								</div>
								<div class="description">
									<h5>Womans Comfort</h5>

									<p>WomansComfort is an eCommerce shopping website with SMS gateway, online payment
										gateway &amp; responsive website. Website has fulfilled customers need in every
										possible way.</p>
								</div>
							</div>
						</a>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="row">
			<div class="col-md-12">
				<div class="load-more" id="load-more">
					<p>
						<i class="fa fa-refresh"></i>
					</p><br>
					<span>Click to load more</span>
				</div>
			</div>
		</div> -->
	</div>
</div>
<div class="blue-design-summary">
    <div class="container">
        <div class="row">
            <div class="col-md-10">
                <h3 class="inverse-text">As a web development company, We help brands educate, engage, and inspire their customers through gamut of online services and powerful designs</h3>
                <h3 class="inverse-text"></h3>
            </div>
            <div class="col-md-2 button-div">
                <a href="javascript:;" class="btn btn-footer modal-btn">
                    Start a Discussion </a>
            </div>
        </div>
    </div>
</div>