<section class="page-title overlay" style="background-image: url(../../assets/images/about-us.jpg);">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 text-center">
                <h2 class="text-white font-weight-bold head-title">We Do Things That Matters </h2>
            </div>
        </div>
    </div>
</section>

<div id="about-us" class="about-section">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-sm-6">
                <div class="about-img">
                    <img src="../../assets/images/visionmission.png" class="img-responsive" alt="About dsBit">
                </div>
            </div>
            <div class="col-md-6 col-sm-6">
                <div class="about-info">
                    <h2 class="mission-txt main-title content-title">Our Mission</h2>
                    <h5 class="common-description text-content">THROUGH TECHNOLOGY, WE TRANSFORM BUSINESSES</h5>
                    <p class="text-content">We confide in offering quality to the customer through our
                        organizations. Our organization is not just suggested for using rather we acknowledge inside
                        giving the customer something that offers something that the customer would love for a long
                        time.</p>

                    <p class="text-content">To surprise and joy our customers with innovative outcomes going before their necessities and to
                        continue with our journey for
                        the enormity and duty towards our clients and business accessories
                        sets the entire route over the globe.</p>

                </div>
            </div>
        </div>
    </div>
</div>
<!--================ #how we work section ==============================================-->
<section class="work-section">
    <div class="container">
        <div class="row mb-70">
            <div class="col-md-6 col-sm-6">
                <div class="work-info">
                    <h2 class="main-title content-title">How We Work</h2>
                    <p class="common-description text-content">To give a win-win condition for all while recollecting our client's
                        necessities and the troubles they defy in the present centered circumstance. We are controlled
                        by exceptionally qualified and master experts having inside and out area information, immense
                        industry encounter and with novel innovation that attention on the profound comprehension of the
                        necessities and procedures to create and send imaginative web answers for our customers in their
                        separate business range.</p>

                    <p class="text-content">We place stock in broadening the advancement of our clients with unrivaled site change and
                        imaginative blueprints.</p>

                </div>
            </div>
            <div class="col-md-6 col-sm-6">
                <img src="../../assets/images/how-we-work.png" class="img-responsive" alt="Web Design">
            </div>
        </div>
    </div>
</section>
<!-- services -->
<div id="meteorjs">
    <div class="feat">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <h2>Faster apps development with latest trends</h2>
                    <p>
                        dsbit is <strong>app development company in Pune
                            India</strong> and we have expert developers. Our
                        developers have preferred  LESS
                        CODE = LESS BUGS = High QUALITY.
                    </p>
                    <div class="row features-row hidden-xs">
                        <div class="col-xs-2">
                            <div class="circle">
                                <img src="../../assets/images/icon-heart.png" class="img-responsive" alt="logo of meteorjs">
                                <h5 id="h5">Delightful apps</h5>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="circle">
                                <img src="../../assets/images/icon-faster.png" class="img-responsive" alt="logo of meteorjs">
                                <h5>Faster Development</h5>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="circle">
                                <img src="../../assets/images/icon-optimistic.png" class="img-responsive" alt="logo of meteorjs">
                                <h5>Optimistic UI</h5>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="circle">
                                <img src="../../assets/images/icon-js.png" class="img-responsive" alt="logo of meteorjs">
                                <h5>JavaScript App Platform</h5>
                            </div>
                        </div>
                        <div class="col-xs-2">
                            <div class="circle">
                                <img src="../../assets/images/icon-integrated.png" class="img-responsive" alt="logo of meteorjs">
                                <h5>
                                    <span class="hidden-xs">Fully </span>Integrated &amp; Flexible
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- stratergy -->
<section class="stratergy-section">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-sm-6">
                <img src="../../assets/images/strategy.png" class="img-responsive" alt="Web Development">
            </div>
            <div class="col-md-6 col-sm-6">
                <div class="stratergy-info">
                    <h2 class="main-title content-title">Our Strategy</h2>
                    <p class="common-description text-content">dsBit Solutions is known for giving Low Cost website composition,
                        quality masterminded, and trustworthy Website Design organizations to clients over the globe.
                    </p>

                    <p class="text-content">Our group of expert makers with a notoriety for making and creating brands through imaginative
                        and advanced media. We adore working with organizations who need excellent web and illustrations
                        arrangements, beginning from the look and feel, to the usefulness of the arrangement.</p>

                    <p class="text-content">We are adaptable and positive about our approach of giving a quality and result situated web
                        arrangement including <a [routerLink]="['/web-development']">Website Design</a>, Web Development, Flash Website outlining, 
                        <a [routerLink]="['/web-development']">E Commerce
                            Website Designing</a>, Domain Registration, Web Hosting and Search Engine Optimization.</p>
                </div>
            </div>
        </div>
    </div>
</section>